import { __assign, __spreadArray } from "tslib";
var gearColors = [
    "#fe7f2d",
    "#a09abc",
    "#2176ae",
    "#4b3b40",
    "#9db17c",
    "#16697a",
    "#cb9cf2",
    "#d64933",
    "#086050",
    "#9d9d24",
    "#3d52d5",
];
var gearColorIndex = 0;
var colorByGear = {};
export function getColorForGear(id) {
    //console.log('Get color for ',id)
    if (customColors[id]) {
        return customColors[id];
    }
    if (colorByGear[id]) {
        return colorByGear[id];
    }
    else {
        colorByGear[id] = gearColors[gearColorIndex % gearColors.length];
        gearColorIndex += 1;
        return colorByGear[id];
    }
}
export var defaultColor = "#ff3e00";
export function getColors() {
    return __spreadArray([defaultColor], gearColors.slice(gearColorIndex));
}
var storedCustomColors = localStorage.getItem("customColors");
var customColors = {
    Ride: "#fe7f2d",
    Walk: "#a09abc",
    Hike: "#2176ae",
    NordicSki: "pink"
};
if (storedCustomColors) {
    try {
        customColors = __assign(__assign({}, customColors), JSON.parse(storedCustomColors));
    }
    catch (err) {
        console.log("Bad custom colors?", storedCustomColors);
    }
    for (var color in Object.values(customColors)) {
        if (gearColors.indexOf(color) > -1) {
            gearColors.splice(gearColors.indexOf(color), 1); // remove one...
        }
    }
}
export function setCustomColor(id, color) {
    console.log("Custom color! ", id, "from ", customColors[id], "==>", color);
    customColors[id] = color;
    localStorage.setItem("customColors", JSON.stringify(customColors));
    console.log("Now we got", customColors[id]);
    if (gearColors.indexOf(color) > -1) {
        gearColors.splice(gearColors.indexOf(color), 1); // remove one...
    }
}

import { __assign, __awaiter, __generator } from "tslib";
import { writable, derived, get } from "svelte/store";
import { getActivities } from "./strava";
export var per_page = writable(50);
var activitiesById = writable(new Map());
var fetchersByDate = writable(new Map());
var now = new Date();
export var endDate = writable(now);
export var startDate = writable(new Date(now.getFullYear(), now.getMonth(), 1, 0)); // first of the month
var fetching = writable(false);
function modifyFetcherForRedundancy(currentParams, $fetchersByDate) {
    var _a = getBaseFetchInfo(), oldest = _a.oldest, newest = _a.newest;
    var updates = {};
    if (newest) {
        // If we have base fetches already, let's assume they were in order from present to past...
        // Imagine we want to fetch 2/1 - 6/1  (after 1/1, before 6/1)
        if (currentParams.before > oldest || !currentParams.before) {
            // If we've already fetched 3/1 - 6/1, then we want to specify before 3/1...
            updates.before = oldest;
        }
        if (currentParams.after > oldest) {
            // And if we've already fetched 1/1 - 6/1, then we don't need to update at all...
            updates.complete = true; //
        }
    }
    if (!updates.complete) {
        // Check our complete time fetches...
        $fetchersByDate.forEach(function (fetcherParams, stampString) {
            if (fetcherParams.complete) {
                var parsed = JSON.parse(stampString);
                var old = {
                    before: parsed[1],
                    after: parsed[0]
                };
                var current = __assign(__assign({}, currentParams), updates);
                // Let's just treat the "0" option for the future like a time in the future to make
                // all of our comparisons below just work...
                var theFuture = 2 * (new Date().getTime() / 1000);
                if (current.before == 0) {
                    current.before = theFuture;
                }
                if (old.before == 0) {
                    old.before = theFuture;
                }
                // We look for three types of overlap...
                if (old.after <= current.after && old.before >= current.before) {
                    // 1. Contained
                    // [less] old.after                                   old.before   [more]
                    //               current.after      current.before
                    console.log("~F~ Opt 1 (contained) by", logF(old), logF(fetcherParams));
                    console.log("~F: ", old.after, current.after, old.before, current.before);
                    updates.complete = true;
                }
                else if (current.after <= old.after &&
                    current.before <= old.before &&
                    current.before > old.after // but not separated
                ) {
                    // 2. Overlaps before
                    // [less]                       old.after                       old.before   [more]
                    //           current.after                 current.before
                    //           [fetch this...     ]
                    console.log("~F~ Opt 2", logF(old), logF(fetcherParams));
                    updates.before = old.after;
                }
                else if (old.after <= current.after &&
                    old.before < current.before &&
                    old.before > current.after) {
                    // 1. Overlaps after
                    // [less]        old.after                       old.before                [more]
                    //                          current.after                    current.before
                    //                                                   [fetch this...     ]
                    console.log("~F~ Opt 3", logF(old), logF(fetcherParams));
                    updates.after = old.before;
                }
                // Set timestamp back from theFuture to 0 so we make request to strava API for no limit
                // as opposed to a weird future limit...
                if (updates.before == theFuture) {
                    updates.before = 0;
                }
            }
        });
    }
    /* if (Object.keys(updates).length) {
      console.log('~F~ Updating',logF(currentParams),'to',logF({...currentParams,...updates}),currentParams,updates);
    } */
    return __assign(__assign({}, currentParams), updates);
    function getBaseFetchInfo() {
        var $activitiesById = get(activitiesById);
        var baseFetcher = $fetchersByDate.get(JSON.stringify([0, 0]));
        if (!baseFetcher || !baseFetcher.activities.size) {
            return { oldest: 0, newest: 0 };
        }
        else {
            var activities = Array.from(baseFetcher.activities);
            var last = activities[activities.length - 1];
            var first = activities[0];
            return {
                oldest: new Date($activitiesById.get(last).start_date).getTime() / 1000,
                newest: new Date($activitiesById.get(first).start_date).getTime() / 1000
            };
        }
    }
}
function logF(fetcher) {
    return "~F~" + new Date(1000 * fetcher.after).toLocaleDateString() + "-" + new Date(1000 * fetcher.before).toLocaleDateString();
}
function fetchActivities(fetcher) {
    return __awaiter(this, void 0, void 0, function () {
        var newActivities_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!fetcher.complete) return [3 /*break*/, 2];
                    return [4 /*yield*/, getActivities(fetcher.page, fetcher.per_page, fetcher.before, fetcher.after)];
                case 1:
                    newActivities_1 = _a.sent();
                    //console.log('fetchActivities=>',logF(fetcher),fetcher,newActivities)
                    activitiesById.update(function ($activitiesById) {
                        for (var _i = 0, newActivities_2 = newActivities_1; _i < newActivities_2.length; _i++) {
                            var activity = newActivities_2[_i];
                            $activitiesById.set(activity.id, activity);
                            fetcher.activities.add(activity.id);
                        }
                        return $activitiesById;
                    });
                    if (newActivities_1.length != fetcher.per_page) {
                        //console.log('fetchActivities=>Complete',logF(fetcher),fetcher)
                        fetcher.complete = true;
                    }
                    fetcher.page += 1;
                    _a.label = 2;
                case 2: return [2 /*return*/, fetcher.activities];
            }
        });
    });
}
function prepopulateActivities(fetcher, $activitiesById) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!fetcher.before && !fetcher.after) {
                // then we are just a list of activities, so any activities we have count...
                $activitiesById.forEach(function (activity, key) { return fetcher.activities.add(key); });
            }
            else {
                $activitiesById.forEach(function (activity, key) {
                    var stamp = new Date(activity.start_date).getTime() / 1000;
                    if (!fetcher.before || stamp < fetcher.before) {
                        if (stamp > fetcher.after) {
                            fetcher.activities.add(key);
                        }
                    }
                });
            }
            return [2 /*return*/];
        });
    });
}
export var activityFetcher = derived([fetchersByDate, activitiesById, startDate, endDate], function (_a) {
    var $fetchersByDate = _a[0], $activitiesById = _a[1], $startDate = _a[2], $endDate = _a[3];
    var startStamp = ($startDate && $startDate.getTime() / 1000) || 0;
    var endStamp = ($endDate && $endDate.getTime() / 1000) || 0;
    if (endStamp && startStamp > endStamp) {
        console.log("~FF no go");
        return {
            broken: true,
            activities: function () { return []; },
            fetchMore: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, true];
            }); }); },
            getFetcher: function () { return ({ complete: true }); }
        };
    }
    var currentFetcher = {
        dateIndex: JSON.stringify([startStamp, endStamp]),
        getFetcher: function () {
            var fetchParams = $fetchersByDate.get(currentFetcher.dateIndex);
            if (!fetchParams) {
                fetchParams = {
                    page: 1,
                    per_page: get(per_page),
                    after: startStamp,
                    before: endStamp,
                    complete: false,
                    activities: new Set()
                };
                console.log("~FF Create new fetcher is:", logF(fetchParams), JSON.parse(JSON.stringify(fetchParams)));
                // Pre-populate fetcher...
                prepopulateActivities(fetchParams, $activitiesById);
                fetchParams = modifyFetcherForRedundancy(fetchParams, $fetchersByDate);
                console.log("~FF Modified params:", logF(fetchParams), fetchParams);
                fetchersByDate.update(function ($f) {
                    $f.set(currentFetcher.dateIndex, fetchParams);
                    return $f;
                });
            }
            return fetchParams;
        },
        activities: function () {
            var fetcher = currentFetcher.getFetcher();
            prepopulateActivities(fetcher, $activitiesById); // in case we have new stuff since we were last fetching...
            var result = Array.from(fetcher.activities.keys(), function (id) {
                return $activitiesById.get(id);
            });
            return result;
        },
        fetchMore: function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetcher;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fetcher = currentFetcher.getFetcher();
                        if (!!fetcher.complete) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchActivities(fetcher)];
                    case 1:
                        _a.sent();
                        fetchersByDate.update(function ($f) { return $f; });
                        _a.label = 2;
                    case 2: return [2 /*return*/, fetcher.complete];
                }
            });
        }); },
        fetchAll: function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetcher;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fetcher = currentFetcher.getFetcher();
                        _a.label = 1;
                    case 1:
                        if (!!fetcher.complete) return [3 /*break*/, 3];
                        return [4 /*yield*/, fetchActivities(fetcher)];
                    case 2:
                        _a.sent();
                        fetchersByDate.update(function ($f) { return $f; });
                        return [3 /*break*/, 1];
                    case 3: return [2 /*return*/, fetcher];
                }
            });
        }); },
        reFetch: function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fetchParams = {
                            page: 1,
                            per_page: get(per_page),
                            after: startStamp,
                            before: endStamp,
                            complete: false,
                            activities: new Set()
                        };
                        fetchersByDate.update(function ($f) {
                            $f.set(currentFetcher.dateIndex, fetchParams);
                            return $f;
                        });
                        return [4 /*yield*/, fetchActivities(fetcher)];
                    case 1:
                        _a.sent();
                        fetchersByDate.update(function ($f) { return $f; });
                        return [2 /*return*/];
                }
            });
        }); }
    };
    return currentFetcher;
});

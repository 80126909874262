import { writable } from 'svelte/store';
var stored = localStorage.getItem('token');
var initialValue;
if (stored) {
    initialValue = JSON.parse(stored);
    console.log('using parsed token!');
}
export var token = writable(initialValue);
export var authenticated = writable(false);
var storedScope = localStorage.getItem('scope');
export var scope = writable(storedScope);
var initialBikeSettings = localStorage.getItem('bikeSettings');
if (initialBikeSettings) {
    try {
        initialBikeSettings = JSON.parse(initialBikeSettings);
        console.log('Got initial settings!', initialBikeSettings);
    }
    catch (err) {
        console.log('Error parsing settings', err);
        initialBikeSettings = [];
    }
}
else {
    initialBikeSettings = [];
}
export var currentPath = writable(window.location.pathname);
export var bikeSettings = writable(initialBikeSettings);

import { __awaiter, __generator } from "tslib";
import { token, authenticated } from './stores';
import { get } from 'svelte/store';
export function checkAuthentication() {
    return __awaiter(this, void 0, void 0, function () {
        var $token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    $token = get(token);
                    if (!$token) return [3 /*break*/, 4];
                    if (!($token.expires_at < new Date().getTime() / 1000)) return [3 /*break*/, 2];
                    authenticated.update(function () { return false; });
                    console.log("Oops, expired", new Date().getTime() / 1000 - $token.expires_at, "seconds ago");
                    return [4 /*yield*/, refreshToken()];
                case 1:
                    _a.sent();
                    return [2 /*return*/, true];
                case 2:
                    console.log('Token is good to go!');
                    authenticated.update(function () { return true; });
                    return [2 /*return*/, true];
                case 3: return [3 /*break*/, 5];
                case 4: return [2 /*return*/, false];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function refreshToken() {
    return __awaiter(this, void 0, void 0, function () {
        var uri, response, jsonResponse, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    uri = "/.netlify/functions/api";
                    console.log("Refreshing token");
                    return [4 /*yield*/, fetch(uri, {
                            method: "POST",
                            body: JSON.stringify({
                                method: "refresh",
                                token: get(token)
                            })
                        })];
                case 1:
                    response = _a.sent();
                    console.log("Got response!");
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, response.json()];
                case 3:
                    jsonResponse = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    console.log("No json? oops!");
                    return [3 /*break*/, 5];
                case 5:
                    console.log("Response", jsonResponse);
                    localStorage.setItem("token", JSON.stringify(jsonResponse.token));
                    token.update(function () { return jsonResponse.token; });
                    authenticated.update(function () { return true; });
                    return [2 /*return*/];
            }
        });
    });
}
export function getActivities(page, per_page, before, after) {
    if (page === void 0) { page = 1; }
    if (per_page === void 0) { per_page = 60; }
    if (before === void 0) { before = 0; }
    if (after === void 0) { after = 0; }
    return __awaiter(this, void 0, void 0, function () {
        var query, response, activities;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkAuthentication()];
                case 1:
                    _a.sent();
                    query = "https://www.strava.com/api/v3/activities?page=" + page + "&per_page=" + per_page;
                    if (before) {
                        query = query + ("&before=" + before);
                    }
                    if (after) {
                        query = query + ("&after=" + after);
                    }
                    return [4 /*yield*/, fetch(query, {
                            headers: {
                                Authorization: "Bearer " + get(token).access_token
                            }
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    activities = _a.sent();
                    console.log('getActivities=>', activities);
                    return [2 /*return*/, activities];
            }
        });
    });
}
export function getAthlete() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkAuthentication()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, fetch(
                        //$ http GET "https://www.strava.com/api/v3/athlete" "Authorization: Bearer [[token]]"
                        "https://www.strava.com/api/v3/athlete", {
                            headers: {
                                Authorization: "Bearer " + get(token).access_token
                            }
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getLatLngStream(activity) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkAuthentication()
                    // $ http GET "https://www.strava.com/api/v3/activities/{id}/streams?keys=&key_by_type=" "Authorization: Bearer [[token]]"
                ];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, fetch(
                        //$ http GET "https://www.strava.com/api/v3/athlete" "Authorization: Bearer [[token]]"
                        "https://www.strava.com/api/v3/activities/" + activity.id + "/streams?keys=latlng,grade_smooth,watts,heartrate,velocity_smooth,distance&keys_by_type=false", {
                            headers: {
                                Authorization: "Bearer " + get(token).access_token
                            }
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
